import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { api } from 'api/api';
import { Site } from 'api/types';

import { RequireAuth } from 'components/RequireAuth/RequireAuth';
import { Test } from 'components/Test';
import { User, userState } from 'data/user';
import { Button } from 'components/Button/Button';

import classNames from './Admin.module.css';

interface AdminData {
	serviceAccounts?: [];
	aliasesBySiteId?: Record<string, string[]>;
	sitesById?: Record<string, Site>;
	feedback?: Array<{ id: number; feedback_text: string }>;
}

const Subheader = ({ text }: { text: string }) => (
	<div className={classNames.subheader}>{text}</div>
);

export const UnprotectedAdmin = () => {
	const [{ isAdmin, initialized }] = useRecoilState<User>(userState);
	const navigate = useNavigate();

	const [data, setData] = React.useState<AdminData>({});

	React.useEffect(() => {
		if (initialized && !isAdmin) {
			navigate('/home', { replace: true });
		} else {
			api.getAdminData().then((resp) => {
				const respData = resp?.data;
				setData(respData);
			});
		}
	}, [isAdmin, initialized, navigate]);

	const sitesAndAliases = React.useMemo(() => {
		if (!data.aliasesBySiteId || !data.sitesById) {
			return null;
		}

		return Object.keys(data.aliasesBySiteId).map((siteId) => {
			const site = data.sitesById?.[siteId];
			return (
				site && (
					<div key={siteId} className={classNames['sites-aliases-container']}>
						<div>
							Site: {site.name} ({site.id})
						</div>
						<span>Aliases:</span>
						{data.aliasesBySiteId?.[siteId]?.map((alias) => (
							<span key={alias}>{alias}</span>
						))}
					</div>
				)
			);
		});
	}, [data]);

	const feedback = React.useMemo(() => {
		if (!data.feedback) {
			return null;
		}

		return data.feedback.map(({ id, feedback_text: text }) => (
			<div className={classNames['feedback-container']}>
				<div>{text}</div>
			</div>
		));
	}, [data]);

	return (
		<div className={classNames.root}>
			<Subheader text="Test Endpoint" />
			<div style={{ maxWidth: 200 }}>
				<Test />
			</div>

			<Subheader text="Service Accounts" />
			<div className="flex-center" style={{ flexWrap: 'wrap' }}>
				{data.serviceAccounts?.map((acc) => (
					<>
						<Button
							key={acc}
							className="copy-service-email"
							onClick={() => {
								navigator.clipboard.writeText(acc);
							}}
						>
							{acc}
						</Button>
						<br />
					</>
				))}
			</div>

			<Subheader text="Feedback" />
			{feedback}

			<Subheader text="Sites & Aliases" />
			{sitesAndAliases}
		</div>
	);
};

export const Admin = () => (
	<RequireAuth>
		<UnprotectedAdmin />
	</RequireAuth>
);
