import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import React from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import * as amplitude from '@amplitude/analytics-browser';

import './App.css';

import { Admin } from 'views/Admin/Admin';
import { Landing } from 'views/Landing/Landing';
import { Login } from 'views/Login/Login';
import { ErrorView } from 'views/Error/Error';
import { Home } from 'views/Home/Home';
import { Signup } from 'views/Signup/Signup';
import { VerifyEmail } from 'views/VerifyEmail/VerifyEmail';
import { TermsOfService } from 'views/TermsOfService/TermsOfService';
import { PrivacyPolicy } from 'views/PrivacyPolicy/PrivacyPolicy';

import { User, userState } from 'data/user';
import { api } from 'api/api';
import { firebaseAuth } from 'api/firebase';

import { Root } from './Root';
import { LoadingPage } from 'components/LoadingPage/LoadingPage';
import { CookiesNotice } from 'components/CookiesNotice/CookiesNotice';
import { subscriptionsState, refreshSubscriptions } from 'data/subscriptions';
import {
	refreshSharedDiscounts,
	sharedDiscountsState,
} from 'data/shared-discounts';

const amplitudeApiKey =
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_AMPLITUDE_PROD_PROJECT_ID
		: process.env.REACT_APP_AMPLITUDE_DEV_PROJECT_ID;
amplitude.init(amplitudeApiKey ?? '');

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <ErrorView />,
		children: [
			{ path: '', element: <Landing /> },
			{ path: 'login', element: <Login /> },
			{ path: 'signup', element: <Signup /> },
			{ path: 'verify-email', element: <VerifyEmail /> },
			{ path: 'home', element: <Home /> },
			{ path: 'admin', element: <Admin /> },
			{ path: 'terms-of-service', element: <TermsOfService /> },
			{ path: 'privacy-policy', element: <PrivacyPolicy /> },
		],
	},
]);

const routesToBlockOnAuthLoad = ['/home', '/login'];

function App() {
	const [{ initialized }, setUser] = useRecoilState<User>(userState);
	const [, setSubscriptions] = useRecoilState(subscriptionsState);
	const [, setSharedDiscountsState] = useRecoilState(sharedDiscountsState);

	React.useEffect(() => {
		onAuthStateChanged(firebaseAuth, async (user) => {
			if (user && user.email) {
				amplitude.setUserId(user.email);
				// user is signed in
				const userData = await api.getUser();
				if (userData.uninitialized) {
					// user has not created an email yet
					setUser({
						email: user.email,
						hasAuth: true,
						initialized: true,
						uninitialized: true,
					});
				} else {
					setUser({
						hasAuth: true,
						initialized: true,
						username: userData.username,
						userSalt: userData.userSalt,
						email: userData.email,
						isAdmin: userData.isAdmin,
					});
					setSubscriptions(await refreshSubscriptions());
					setSharedDiscountsState(await refreshSharedDiscounts());
				}
			} else {
				// user is signed out
				setUser({ hasAuth: false, initialized: true });
			}
		});
	}, [setUser, setSubscriptions, setSharedDiscountsState]);

	if (
		routesToBlockOnAuthLoad.includes(window.location.pathname) &&
		!initialized
	) {
		return <LoadingPage />;
	}

	return (
		<React.Suspense fallback={<LoadingPage />}>
			<div className="App">
				<RouterProvider router={router} />
				<CookiesNotice />
			</div>
		</React.Suspense>
	);
}

export default App;
