export const getErrorForUsername = (username: string) => {
	if (username.length < 4) {
		return 'username must be at least 4 chars';
	}
	if (username.length > 20) {
		return 'username must be less than 20 characters';
	}
	if (!/^[a-z0-9]+$/.test(username)) {
		return 'usernames can only have lowercase letters and numbers';
	}
	return null;
};

export const getErrorForEmail = (email: string) => {
	if (!/^[\w-.+]+@([\w-]+.)+[\w-]{2,4}$/.test(email)) {
		return 'invalid email';
	}
	return null;
};

export const getErrorForPassword = (password: string) => {
	if (password.length < 8) {
		return 'password too short';
	}
	return null;
};
