import { useRecoilState } from 'recoil';

import { modalsState } from 'data/modals';

import classNames from './FeedbackModal.module.css';
import { useInputState } from 'hooks';
import { Button } from 'components/Button/Button';
import { api } from 'api/api';

export const FeedbackModal = () => {
	const [{ feedback }, setModalsState] = useRecoilState(modalsState);

	const [feedbackText, onFeedbackTextChange, { reset: resetFeedbackText }] =
		useInputState({
			defaultValue: '',
		});

	const handleClose = () => {
		setModalsState({ feedback: { show: false } });
	};

	const handleSubmit = () => {
		api.sendFeedback(feedbackText);
		resetFeedbackText();
		handleClose();
	};

	if (!feedback.show) {
		return null;
	}

	return (
		<div className={classNames.backdrop} onClick={handleClose}>
			<div
				className={classNames['modal-root']}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={classNames.header}>Give Feedback</div>
				<textarea
					className={classNames.textarea}
					value={feedbackText}
					onChange={onFeedbackTextChange}
					maxLength={500}
					rows={8}
					cols={50}
				/>
				<Button disabled={feedbackText.length === 0} onClick={handleSubmit}>
					Submit
				</Button>
			</div>
		</div>
	);
};
