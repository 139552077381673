const signupPrefix = 'signup:';
const loginPrefix = 'login:';
const navPrefix = 'nav:';
const landingPrefix = 'landing:';

export const EVENTS = {
	signup: {
		viewStep: `${signupPrefix} view step`,
		focusEmailField: `${signupPrefix} focus email field`,
		clickEmailSignup: `${signupPrefix} click email signup`,
		clickGoogleSignup: `${signupPrefix} click google signup`,
		focusUsernameField: `${signupPrefix} focus username field`,
		clickCreateAccount: `${signupPrefix} click create account`,
		signup: `${signupPrefix} create account`, // triggered on backend
	},
	login: {
		focusEmailField: `${loginPrefix} focus email field`,
		clickLogin: `${loginPrefix} click login`,
		clickGoogleLogin: `${loginPrefix} click google login`,
		clickSignUp: `${loginPrefix} click signup`,
	},
	nav: {
		clickJoinUp: `${navPrefix} click join up`,
		clickLogin: `${navPrefix} click login`,
		clickLogo: `${navPrefix} click logo`,
	},
	landing: {
		clickHeaderCTA: `${landingPrefix} click header cta`,
		scroll: `${landingPrefix} scroll`,
	},
};
