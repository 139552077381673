import React from 'react';
import { api } from 'api/api';
import { Button } from './Button/Button';

export const Test = () => {
	const handleHitTestEndpoint = () => {
		api
			.doTest()
			.then((resp) => {})
			.catch((error) => {});
	};

	return <Button onClick={handleHitTestEndpoint}>hit test endpoint</Button>;
};
