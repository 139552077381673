import { api } from 'api/api';
import { Subscription } from 'api/types';
import { atom } from 'recoil';

export interface SubscripionsState {
	initialized: boolean;
	error?: boolean;
	data: Subscription[];
}

export const refreshSubscriptions = async (): Promise<SubscripionsState> => {
	try {
		const resp = await api.getSubscriptions();
		return {
			data: resp,
			initialized: true,
			error: false,
		};
	} catch {
		return {
			data: [],
			initialized: true,
			error: true,
		};
	}
};

export const subscriptionsState = atom<SubscripionsState>({
	key: 'subscriptions',
	default: { data: [], initialized: false, error: false },
});
