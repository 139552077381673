import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { BsPersonFill } from 'react-icons/bs';
import { track } from '@amplitude/analytics-browser';

import { userState } from 'data/user';
import { EVENTS } from 'amp-events';

import classNames from './Nav.module.css';
import { Logo } from 'components/Logo/Logo';
import { api } from 'api/api';
import { subscriptionsState } from 'data/subscriptions';
import { ProfileMenu } from './components/ProfileMenu/ProfileMenu';
import { IconButton } from 'components/IconButton/IconButton';

interface NavProps {
	background: 'light' | 'dark';
}
export const Nav = ({ background }: NavProps): JSX.Element => {
	const navigate = useNavigate();

	const [showProfileMenu, setShowProfileMenu] = React.useState(false);
	const [user, setUser] = useRecoilState(userState);
	const resetSubscriptions = useResetRecoilState(subscriptionsState);

	const handleLogout = () => {
		setShowProfileMenu(false);
		api.logout().then((_resp) => {
			setUser({ hasAuth: false, initialized: true });
			resetSubscriptions();
			navigate({ pathname: '/' });
		});
	};

	return (
		<div
			className={classNames.root}
			style={{
				background: background === 'dark' ? '#e8ebff' : '#ffffff',
			}}
		>
			<button
				onClick={() => {
					track(EVENTS.nav.clickLogo);
					navigate(user.hasAuth && !user.uninitialized ? '/home' : '/');
				}}
				className={classNames['title-button']}
			>
				<Logo width={32} height={32} />
				<span className={classNames.title}>Couper</span>
			</button>
			{user.hasAuth ? (
				<div className={classNames['profile-menu-container']}>
					<IconButton onClick={() => setShowProfileMenu(true)}>
						<BsPersonFill />
					</IconButton>
					{showProfileMenu && (
						<ProfileMenu
							onClose={() => setShowProfileMenu(false)}
							user={user}
							onLogout={handleLogout}
						/>
					)}
				</div>
			) : (
				<div>
					<Link
						onClick={() => {
							track(EVENTS.nav.clickLogin);
						}}
						className={classNames.login}
						to="/login"
					>
						Login
					</Link>
					<Link
						onClick={() => {
							track(EVENTS.nav.clickJoinUp);
						}}
						to="/signup"
						className={classNames['join-up']}
					>
						Join Up
					</Link>
				</div>
			)}
		</div>
	);
};
