import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import { sendSignInLinkToEmail } from 'firebase/auth';

import { firebaseAuth } from 'api/firebase';
import { api } from 'api/api';
import { logger } from 'logger';
import { useInputState, useScrollToTop } from 'hooks';
import { isValidEmail } from 'utils';
import { EVENTS } from 'amp-events';

import { RequireNoAuth } from 'components/RequireNoAuth/RequireNoAuth';
import { Footer } from 'components/Footer/Footer';
import { GoogleSignIn } from 'components/GoogleSignIn/GoogleSignIn';
import { Loading } from 'components/Loading/Loading';
import { Button } from 'components/Button/Button';

import classNames from './Login.module.css';

const UnprotectedLogin = (): JSX.Element => {
	const [email, onChangeEmail] = useInputState({});
	const [isLoading, setIsLoading] = React.useState(false);

	const navigate = useNavigate();

	useScrollToTop(true);

	const handleSignInWithgoogle = () => {
		setIsLoading(true);
		track(EVENTS.login.clickGoogleLogin);
		api
			.authenticateUserWithGoogle()
			.then((resp) => {
				track('login');
				logger.log(resp);
				navigate({ pathname: '/home' });
			})
			.catch((err) => {
				logger.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSignInWithEmail = () => {
		setIsLoading(true);
		track(EVENTS.login.clickLogin);
		// https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0&hl=en
		sendSignInLinkToEmail(firebaseAuth, email, {
			url: `${window.origin}/verify-email`,
			handleCodeInApp: true, // this must be true
		})
			.then(() => {
				window.localStorage.setItem('emailForSignIn', email);
				setIsLoading(false);
			})
			.catch((err) => {
				logger.log(err);
			});
	};

	const resetEmail = () => {
		window.localStorage.removeItem('emailForSignIn');
		window.location.reload();
	};

	const awaitingEmailLogin = window.localStorage.getItem('emailForSignIn');

	return (
		<div>
			<div className={classNames.root}>
				<div className={classNames['form-container']}>
					<div className={classNames['form-header']}>Login</div>

					{isLoading && <Loading />}

					{awaitingEmailLogin && !isLoading && (
						<>
							<div>
								We sent an email to the address you provided. Please follow the
								link in the email to sign in
							</div>
							<button
								onClick={resetEmail}
								className={classNames['different-email']}
							>
								Sign In with different email
							</button>
						</>
					)}

					{!awaitingEmailLogin && !isLoading && (
						<div>
							<div className={classNames['email-container']}>
								<input
									className={classNames['email-input']}
									value={email}
									onChange={onChangeEmail}
									placeholder="email"
									onFocus={() => track(EVENTS.login.focusEmailField)}
								/>
								<Button
									disabled={!isValidEmail(email)}
									onClick={handleSignInWithEmail}
									loading={isLoading}
								>
									Login
								</Button>
							</div>
							<div style={{ margin: 8 }}>or</div>

							<GoogleSignIn
								text="Login with Google"
								onClick={handleSignInWithgoogle}
							/>
							<div style={{ marginTop: 16 }}>
								<Link
									onClick={() => track(EVENTS.login.clickSignUp)}
									className={classNames['create-account']}
									to="/signup"
								>
									Sign Up
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export const Login = () => (
	<RequireNoAuth>
		<UnprotectedLogin />
	</RequireNoAuth>
);
