import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { userState, User } from 'data/user';
import { Loading } from 'components/Loading/Loading';

export const RequireAuth = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const [user] = useRecoilState<User>(userState);

	if (!user.initialized) {
		return <Loading />;
	}

	return user.hasAuth ? (
		<React.Fragment>{children}</React.Fragment>
	) : (
		<Navigate to={'/login'} replace />
	);
};
