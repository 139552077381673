class Logger {
	shouldLog: boolean;

	constructor() {
		this.shouldLog = process.env.NODE_ENV === 'development';
	}

	log = (...args: any[]) => {
		if (this.shouldLog) {
			console.log(...args);
		}
	};

	error = (...args: any[]) => {
		if (this.shouldLog) {
			console.error(...args);
		}
	};

	info = (...args: any[]) => {
		if (this.shouldLog) {
			console.info(...args);
		}
	};
}

export const logger = new Logger();
