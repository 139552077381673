import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { useRecoilState } from 'recoil';
import { FaRegCopy, FaSearch } from 'react-icons/fa';
import { MdClose, MdOutlineFeedback } from 'react-icons/md';

import { User, userState } from 'data/user';
import { useDebounce } from 'hooks';

import { RequireAuth } from 'components/RequireAuth/RequireAuth';
import { Footer } from 'components/Footer/Footer';
import { IconButton } from 'components/IconButton/IconButton';

import classNames from './Home.module.css';
import { useNavigate } from 'react-router-dom';
import { Tabs } from '../../components/Tabs/Tabs';
import { SharedDiscounts } from './components/SharedDiscounts/SharedDiscounts';
import { YourSubscriptions } from './components/YourSubscriptions/YourSubscriptions';
import { modalsState } from 'data/modals';

const UnprotectedHome = (): JSX.Element => {
	const [user] = useRecoilState<User>(userState);
	const [, setModalsState] = useRecoilState(modalsState);

	const navigate = useNavigate();

	const [copied, setCopied] = React.useState<boolean>(false);
	const [searchText, setSearchText] = React.useState<string>('');
	const [debouncedFilterText, setDebouncedFilterText] = React.useState<
		string | undefined
	>(undefined);
	const [tabIndex, setTabIndex] = React.useState(0);
	//const [recentDiscounts, setRecentDiscounts] = React.useState<
	//	Discount[] | null
	//>(null);

	const safeSetDebouncedFilterText = React.useCallback(
		(search: string) => setDebouncedFilterText(search),
		[]
	);
	const debouncedSetFilterText = useDebounce(safeSetDebouncedFilterText, 300);

	const handleCopyEmail = () => {
		navigator.clipboard.writeText(
			`${user.username}-${user.userSalt}@mycouper.com`
		);
		setCopied(true);
		track('copied email');
	};

	React.useEffect(() => {
		if (copied) {
			let timeout = setTimeout(() => setCopied(false), 3000);
			return () => clearTimeout(timeout);
		}
	}, [copied]);

	React.useEffect(() => {
		if (user.uninitialized) {
			navigate('/signup');
		}
	}, [user.uninitialized, navigate]);

	//React.useEffect(() => {
	//	api.getRecentDiscounts().then((discounts) => {
	//		setRecentDiscounts(discounts);
	//	});
	//}, []);

	return (
		<>
			<div className={classNames.root}>
				<div className={classNames['inner-root']}>
					<div className={classNames.header}>
						<div className="flex-center">
							<span className="flex-center">
								<div className={classNames.email}>
									{user.username}-{user.userSalt}@mycouper.com
								</div>
								<div style={{ margin: '0 8px' }}>
									<IconButton
										onClick={handleCopyEmail}
										tooltipText={copied ? 'email copied!' : 'copy email'}
									>
										<FaRegCopy />
									</IconButton>
								</div>
							</span>
						</div>
						<div className="flex-center">
							<div className={classNames.search}>
								<input
									value={searchText}
									onChange={(e) => {
										setSearchText(e.target.value);
										debouncedSetFilterText(e.target.value);
									}}
									placeholder="search sites"
									autoFocus
								/>
							</div>
							<IconButton
								style={{ marginLeft: 12 }}
								onClick={
									searchText === null
										? () => setSearchText('')
										: () => {
												setSearchText('');
												setDebouncedFilterText('');
										  }
								}
							>
								{searchText ? <MdClose /> : <FaSearch />}
							</IconButton>
						</div>
					</div>

					<div className={classNames.content}>
						<div className="flex-center" style={{ margin: '0 0 12px' }}>
							<Tabs
								index={tabIndex}
								onChange={setTabIndex}
								tabs={['Your Sites', 'Couper Sites']}
								small={window.innerWidth < 768}
							/>
						</div>

						{tabIndex === 0 && (
							<>
								<div className={classNames['tab-subtext']}>
									These are the sites you subscribed to with your couper email
									address. Click a site to see discounts codes and emails they
									sent you.
								</div>
								<YourSubscriptions filter={debouncedFilterText} />
							</>
						)}

						{tabIndex === 1 && (
							<>
								<div className={classNames['tab-subtext']}>
									These are sites with discount codes Couper has found in the
									last month. Click a site to see its discount codes.
								</div>
								<SharedDiscounts filter={debouncedFilterText} />
							</>
						)}
					</div>
				</div>
				<IconButton
					onClick={() => {
						setModalsState({ feedback: { show: true } });
					}}
					className={classNames['feedback-fab']}
				>
					<MdOutlineFeedback />
				</IconButton>
			</div>
			<Footer />
		</>
	);
};

export const Home = () => (
	<RequireAuth>
		<UnprotectedHome />
	</RequireAuth>
);
