import { track } from '@amplitude/analytics-browser';
import React from 'react';
import * as DOMPurify from 'dompurify';

import { MailData } from 'api/types';
import { api } from 'api/api';

import { eatClick } from 'utils';

import { Button } from 'components/Button/Button';

import classNames from './ViewEmailModal.module.css';

const getWrappedHTML = (html: string) => `
<html>
	<head>
		<meta http-equiv="Content-Security-Policy" content="script-src 'none'">
		<base target="_blank">
		<style>
			body { display: inline-block; }
			img { max-width: 500px; }
		</style>
	</head>
	<body>
		<span style="display: inline-block;" id="vem-inner-body">
			${html}
		</span>
	</body>
</html>`;

export const ViewEmailModal = ({
	emailId,
	onClose,
}: {
	emailId: string | null;
	onClose: () => void;
}) => {
	const [loadedEmail, setLoadedEmail] = React.useState<MailData | null>(null);

	const sanitizedHTML = React.useMemo(
		() => (loadedEmail ? DOMPurify.sanitize(loadedEmail.html) : null),
		[loadedEmail]
	);

	const _reframeEmail = () => {
		const iframeEl: HTMLIFrameElement | null = document.getElementById(
			'vem-iframe'
		) as HTMLIFrameElement;
		const innerBodyEl =
			iframeEl?.contentWindow?.document.getElementById('vem-inner-body');
		const bodyEl =
			iframeEl?.contentWindow?.document.getElementsByTagName('body')[0];

		if (innerBodyEl && iframeEl && bodyEl) {
			const scaleToSet = iframeEl.clientWidth / innerBodyEl.clientWidth - 0.05;
			bodyEl.style.transform = `scale(${scaleToSet.toFixed(6)})`;
			bodyEl.style.transformOrigin = '0 0';
		}
	};

	React.useEffect(() => {
		if (emailId) {
			const load = async () => {
				const email = await api.getEmail(emailId);
				setLoadedEmail(email);
			};
			load();
		}
	}, [emailId]);

	React.useEffect(() => {
		track('view email');
	}, []);

	return (
		<div className={classNames.overlay} onClick={onClose}>
			<div className={classNames.root} onClick={eatClick}>
				<div className={classNames.header}>
					<div>Sender: {loadedEmail?.sender}</div>
					<div>
						<Button onClick={onClose}>Close</Button>
					</div>
				</div>
				<div className={classNames.message}>
					Only read and interact with emails when you trust the sender. We
					recommend copying codes from the dashboard.
				</div>
				{/* Rendering random email html is dangerous, obviously, so we HAVE to sanitize the html above,
			and do a bunch of shit I found here https://making.close.com/posts/rendering-untrusted-html-email-safely
			to render it safely, the DOMPurify package should be kept up to date */}
				{sanitizedHTML && (
					<iframe
						title="email-content"
						id="vem-iframe"
						style={{
							width: 'calc(100% - 20px)',
							height: 'calc(100% - 30px)',
							display: 'inline-block',
						}}
						srcDoc={getWrappedHTML(sanitizedHTML)}
						sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin"
						// @ts-expect-error // not all browsers support but some do
						csp="script-src 'none'"
					/>
				)}
			</div>
		</div>
	);
};
