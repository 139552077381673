import React from 'react';

import { Button } from 'components/Button/Button';

import classNames from './CookiesNotice.module.css';

const COOKIE_NAME = 'coup-cookie-control';

export const CookiesNotice = () => {
	const [hasAccepted, setHasAccepted] = React.useState<boolean>(false);

	const handleAccept = () => {
		const acceptedCookies = {
			all: true,
		};
		document.cookie = `${COOKIE_NAME}=${JSON.stringify(acceptedCookies)}`;
		setHasAccepted(true);
	};

	React.useEffect(() => {
		const acceptedCookiesString = document.cookie
			.split('; ')
			.find((row) => row.startsWith(`${COOKIE_NAME}=`))
			?.split('=')[1];

		if (acceptedCookiesString) {
			const acceptedCookies = JSON.parse(acceptedCookiesString);
			if (acceptedCookies.all) {
				setHasAccepted(true);
				return;
			}
		}
	}, []);

	if (hasAccepted) {
		return null;
	}

	return (
		<div className={classNames.root}>
			<div className={classNames.header}>Cookies & Privacy Notice</div>
			<div>
				We use cookies to provide important website functionality, improve your
				experience, and analyze our site traffic. By using our website, you
				agree to our <a href="/privacy-policy">Privacy Policy</a> and our
				cookies usage
			</div>
			<Button className={classNames.accept} onClick={handleAccept}>
				Accept
			</Button>
		</div>
	);
};
