import React from 'react';
import cx from 'classnames';

import { BsCheckLg } from 'react-icons/bs';

import classNames from './SignupProgress.module.css';

const steps = ['Verify Email', 'Pick Username', 'Start Saving'];

export const SignupProgress = ({ currentStep }: { currentStep: number }) => {
	return (
		<div className={classNames.root}>
			{steps.map((step, i) => (
				<React.Fragment key={`step-${i}`}>
					<div className={classNames['step-container']}>
						<div
							className={cx(
								classNames['step-number'],
								currentStep > i && classNames['completed'],
								currentStep < i && classNames['not-yet']
							)}
						>
							{currentStep > i ? <BsCheckLg /> : i + 1}
						</div>
						<div
							className={cx(
								classNames['step-text'],
								i > currentStep && classNames['not-yet']
							)}
						>
							{step}
						</div>
					</div>

					{i < steps.length - 1 && (
						<div
							className={cx(
								classNames['step-connector'],
								i >= currentStep && classNames['not-yet']
							)}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	);
};
