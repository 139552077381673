import React from 'react';

export const SeeContactEmail = () => {
	const [canSee, setCanSee] = React.useState(false);

	return (
		<span
			style={{
				color: canSee ? 'inherit' : '#3366CC',
				cursor: canSee ? 'default' : 'pointer',
			}}
			onClick={() => setCanSee(true)}
			role="button"
		>
			{canSee ? process.env.REACT_APP_CONTACT_EMAIL : 'click here to see email'}
		</span>
	);
};
