import React from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { userState, User } from 'data/user';

export const RequireNoAuth = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const [user] = useRecoilState<User>(userState);

	return !user.hasAuth ? (
		<React.Fragment>{children}</React.Fragment>
	) : (
		<Navigate to={'/home'} replace />
	);
};
