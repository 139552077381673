import { Link } from 'react-router-dom';
import { MdCopyright } from 'react-icons/md';

import classNames from './Footer.module.css';
import { DateTime } from 'luxon';

export const Footer = () => {
	return (
		<div className={classNames.root}>
			<div className="flex-center">
				<Link to="/terms-of-service" className={classNames.link}>
					Terms of Service
				</Link>
				<div className={classNames['link-divider']} />
				<Link to="/privacy-policy" className={classNames.link}>
					Privacy Policy
				</Link>
			</div>
			<div className={classNames.copyright}>
				<div className="flex-center" style={{ margin: '0 4px' }}>
					Copyright
					<span className="flex-center" style={{ margin: '0 4px' }}>
						<MdCopyright />
					</span>
					{DateTime.now().year}
				</div>
				Landolfi Ventures, LLC. All rights reservered.
			</div>
		</div>
	);
};
