import React from 'react';
import { FiTag } from 'react-icons/fi';
import { Link, useSearchParams } from 'react-router-dom';
import { Identify, track, identify } from '@amplitude/analytics-browser';

import { useScrollToTop } from 'hooks';
import { api } from 'api/api';
import { EVENTS } from 'amp-events';
import { throttleFunction } from 'utils';

import { Footer } from 'components/Footer/Footer';

import classNames from './Landing.module.css';

const fallbackNumDiscounts = 600;

export const Landing = () => {
	useScrollToTop(true);

	const [params] = useSearchParams();
	const adSource = params.get('ad-source');

	const [numDiscounts, setNumDiscounts] = React.useState(0);

	const discountNumbers = React.useMemo(() => {
		let remainingDiscounts = numDiscounts - 1; // so we can add a plus
		const nums = [];
		while (remainingDiscounts > 0) {
			nums.unshift(remainingDiscounts % 10);
			remainingDiscounts = Math.floor(remainingDiscounts / 10);
		}
		return nums;
	}, [numDiscounts]);

	React.useEffect(() => {
		api
			.countDiscounts()
			.then((resp) => {
				setNumDiscounts(resp.data.count);
			})
			.catch(() => {
				setNumDiscounts(fallbackNumDiscounts);
			});
	}, []);

	React.useEffect(() => {
		if (adSource) {
			const identifyObj = new Identify();
			identifyObj.setOnce('ad-source', adSource);
			identify(identifyObj);
		}
	}, [adSource]);

	React.useEffect(() => {
		const cb = throttleFunction(() => {
			track(EVENTS.landing.scroll, { y: window.scrollY });
		});

		window.addEventListener('scroll', cb);
		return () => window.removeEventListener('scroll', cb);
	}, []);

	return (
		<>
			<div id="landing-root" className={classNames.root}>
				<div className={classNames.header}>
					<div className={classNames['header-inner-container']}>
						<div className={classNames['header-left']}>
							<div className={classNames['header-title']}>
								<div style={{ marginBottom: 16 }}>Tired of spam?</div>
								<span className={classNames['header-title-name']}>Couper</span>
								&nbsp;shields you from spam emails and finds you coupons
							</div>
							<div className={classNames['header-email-container']}>
								<Link
									onClick={() => {
										track(EVENTS.landing.clickHeaderCTA);
									}}
									to="/signup"
									className={classNames['get-started']}
								>
									Get Started
								</Link>
							</div>
						</div>
						<img
							className={classNames['header-image']}
							alt="deals"
							src="/landing/header-balloon.webp"
						/>
					</div>
				</div>

				{numDiscounts > 0 && (
					<div>
						<div className={classNames['counter-container']}>
							{discountNumbers.map((num, i) => (
								<div
									className={classNames['counter-number']}
									key={`counter-${num}-${i}`}
								>
									{num}
								</div>
							))}
							<div className={classNames.plus}>+</div>
						</div>
						<div className={classNames['found-so-far']}>
							Coupons found so far
						</div>
					</div>
				)}

				{/*<div className={classNames.subheader}>
					<span className={classNames['subheader-text']}>
						A shopping tool that does all the work
					</span>
					<div className={classNames['subheader-image-container']}>
						<img
							src="/landing/dashboard-PLACEHOLDER.png"
							alt="image of dashboard"
						/>
					</div>
				</div>*/}

				<div className={classNames['hiw-container']}>
					<div className={classNames['hiw-title']}>
						<span>How it works</span>{' '}
						<FiTag fill="var(--primary-main)" stroke="#fff" />
					</div>
					<div className={classNames['hiw-subtitle']}>
						Online retailers are sending more spam mail than ever. Couper takes
						care of that spam and pulls out the coupon codes just for you!
					</div>

					<div className={classNames['fold-row']}>
						<img
							alt="happy-woman-using-phone"
							src="/landing/man-juice-phone.webp"
							className={classNames['fold-image']}
						/>
						<div className={classNames['fold-row-column']}>
							<div className={classNames['fold-step']}>
								<span>
									<img alt="star" src="/landing/star.svg" />
								</span>
								Step 1
							</div>
							<div className={classNames['fold-row-text']}>
								When you sign up for Couper you&apos;ll get a personalized email
								address
							</div>
							<div className={classNames['fold-subtext']}>
								On average, a Couper account stops 250+ emails a month from
								polluting your inbox
							</div>
						</div>
					</div>

					<div className={classNames['fold-row']}>
						<div className={classNames['fold-row-column']}>
							<div className={classNames['fold-step']}>
								<span>
									<img alt="star" src="/landing/star.svg" />
								</span>
								Step 2
							</div>
							<div className={classNames['fold-row-text']}>
								Use that email anytime an online store offers you a discount
							</div>
							<div className={classNames['fold-subtext']}>
								We find a discount code in up almost 25% of the emails we
								receive!
							</div>
						</div>
						<img
							alt="happy-man-holding-bag-and-phone"
							src="/landing/girl-excited-phone.webp"
							className={classNames['fold-image']}
						/>
					</div>
				</div>
				<div className={classNames['fold-column']}>
					<div className={classNames['fold-step']}>
						<span>
							<img alt="star" src="/landing/star.svg" />
						</span>
						Step 3
					</div>
					<div className={classNames['fold-row-text']}>
						Stop getting spam and start getting discounts!
					</div>
					<img
						alt="happy-man-holding-coupon"
						src="/landing/man-jumping-excited.webp"
						className={classNames['bottom-photo']}
					/>
				</div>
			</div>
			<Footer />
		</>
	);
};
