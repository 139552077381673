import axios from 'axios';
import { logger } from 'logger';
import {
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	UserCredential,
} from 'firebase/auth';

import { firebaseAuth, gAuthProvider } from './firebase';

import { API_URL_DEV, API_URL_PROD } from './constants';
import { APIEndpoint, GeneralDiscount, Site, Subscription } from './types';
import { setUserId, reset } from '@amplitude/analytics-browser';
import { isValidEmail } from 'utils';

const getAPIEndpointURL = (endpoint: APIEndpoint, remainingPath?: string) => {
	return process.env.NODE_ENV === 'production'
		? `${API_URL_PROD}${endpoint}${remainingPath || ''}`
		: `${API_URL_DEV}${endpoint}${remainingPath || ''}`;
};

class API {
	//constructor() {}

	getAxiosConfig = async (forceRefresh?: boolean) => {
		try {
			const idToken = await firebaseAuth.currentUser?.getIdToken(forceRefresh);

			if (idToken) {
				return {
					headers: { Authorization: `Bearer ${idToken}` },
				};
			} else {
				throw new Error('No idToken');
			}
		} catch (error) {
			logger.log('Error in getAxiosConfig ', error);
			return {};
		}
	};

	login = async ({
		email,
		password,
	}: {
		email: string;
		password: string;
	}): Promise<UserCredential> => {
		return signInWithEmailAndPassword(firebaseAuth, email, password);
	};

	logout = async (): Promise<void> => {
		reset();
		return signOut(firebaseAuth);
	};

	authenticateUserWithGoogle = async () => {
		return signInWithPopup(firebaseAuth, gAuthProvider).then((resp) => {
			setUserId(resp.user.email ?? undefined);
			return resp;
		});
	};

	createUser = async ({
		username,
		userSalt,
		acceptedTOSPP,
	}: {
		username: string;
		userSalt: string;
		acceptedTOSPP: boolean;
	}) => {
		const config = await this.getAxiosConfig();
		return axios.post(
			getAPIEndpointURL(APIEndpoint.CREATE_USER),
			{ username, userSalt, acceptedTOSPP },
			config
		);
	};

	countDiscounts = async () => {
		const data = await axios.get(
			getAPIEndpointURL(APIEndpoint.COUNT_DISCOUNTS)
		);

		return data;
	};

	getUserDiscounts = async () => {
		const config = await this.getAxiosConfig();
		const data = await axios.get(
			getAPIEndpointURL(APIEndpoint.USER_DISCOUNTS),
			config
		);

		return data;
	};

	getUser = async (): Promise<{
		username?: string;
		userSalt?: string;
		email?: string;
		isAdmin?: boolean;
		uninitialized?: boolean;
	}> => {
		const config = await this.getAxiosConfig();

		const { data } = await axios.get(
			getAPIEndpointURL(APIEndpoint.USER),
			config
		);

		return {
			username: data.username,
			userSalt: data.userSalt,
			email: data.email,
			isAdmin: data.isAdmin,
			uninitialized: data.uninitialized,
		};
	};

	getEmails = async () => {
		const config = await this.getAxiosConfig();
		const data = await axios.get(
			getAPIEndpointURL(APIEndpoint.EMAILS, '/all'),
			config
		);

		return data;
	};

	getEmail = async (emailId: string) => {
		const config = await this.getAxiosConfig();
		const data = await axios.get(
			getAPIEndpointURL(APIEndpoint.EMAILS, `/${emailId}`),
			config
		);
		const email = data.data.email;
		return email;
	};

	getSubscriptions = async (): Promise<Subscription[]> => {
		const config = await this.getAxiosConfig();
		const { data } = await axios.get(
			getAPIEndpointURL(APIEndpoint.SUBSCRIPTIONS),
			config
		);

		return data?.allSubs ?? [];
	};

	getSharedDiscounts = async (): Promise<{
		sharedDiscounts: Record<Site['id'], GeneralDiscount[]>;
		sitesById: Record<Site['id'], Site>;
	}> => {
		const config = await this.getAxiosConfig();
		const { data } = await axios.get(
			getAPIEndpointURL(APIEndpoint.SHARED_DISCOUNTS),
			config
		);

		return data ?? {};
	};

	signupForWaitlist = async (email: string): Promise<void> => {
		if (!isValidEmail(email)) {
			throw new Error('Email has invalid characters');
		}

		return axios.post(getAPIEndpointURL(APIEndpoint.WAITLIST), { email });
	};

	sendFeedback = async (feedbackText: string): Promise<void> => {
		const config = await this.getAxiosConfig();
		return axios.post(
			getAPIEndpointURL(APIEndpoint.FEEDBACK),
			{ feedbackText },
			config
		);
	};

	verifyInDevelopment = () => {
		if (process.env.NODE_ENV !== 'development') {
			throw new Error('Not in development');
		}
	};

	doTest = async () => {
		this.verifyInDevelopment();
		const config = await this.getAxiosConfig();

		try {
			const data = await axios.get(getAPIEndpointURL(APIEndpoint.TEST), config);

			return data;
		} catch (error) {
			logger.log(error);
		}
	};

	doTestIncomingMail = async () => {
		this.verifyInDevelopment();

		try {
			await axios.post('http://localhost:8080/incoming-mail/v2', {
				messageId: '7hm90d50hrej6ebsp5m2fkp5qkq8q9gbg8b8beo1',
			});
		} catch (error) {
			logger.error(error);
		}
	};

	getAdminData = async () => {
		const config = await this.getAxiosConfig();

		try {
			const data = await axios.get(
				getAPIEndpointURL(APIEndpoint.ADMIN_DATA),
				config
			);

			return data;
		} catch (error) {
			logger.error(error);
		}
	};
}

export const api = new API();
