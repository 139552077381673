import React from 'react';

export const randomInt = (max: number = 10000): number => {
	return Math.floor(Math.random() * max);
};

export const noop = (): void => {};

export const eatClick = (e: React.SyntheticEvent): void => {
	e.preventDefault();
	e.stopPropagation();
};

export const isValidEmail = (email: string): boolean => {
	return /[\w\d-+\.]{3,}@[\w]+\.\w{2,6}/.test(email);
};

export const throttleFunction = (
	callbackFn: () => void,
	limit: number = 100
): (() => void) => {
	let wait = false;
	return () => {
		if (!wait) {
			callbackFn();
			wait = true;
			setTimeout(() => {
				wait = false;
			}, limit);
		}
	};
};
