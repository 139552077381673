import { DateTime } from 'luxon';

import { Email } from 'api/types';

import classNames from './EmailRow.module.css';

export const EmailRow = ({
	email,
	onSelect,
}: {
	email: Email;
	onSelect: () => void;
}) => {
	const received = DateTime.fromISO(email.receivedAt);

	return (
		<div role="button" onClick={onSelect} className={classNames['root']}>
			<span className={classNames['subject']}>{email.subjectStart}</span>
			<span className="flex-center">
				<span style={{ width: 75, marginRight: 8 }}>
					{received.toFormat('MM/dd/yy')}
				</span>
			</span>
		</div>
	);
};
