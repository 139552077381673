import classNames from './Tabs.module.css';
import cx from 'classnames';

export const Tabs = ({
	index,
	onChange,
	tabs,
	small,
}: {
	index: number;
	onChange: (newIndex: number) => void;
	tabs: string[];
	small?: boolean;
}) => {
	return (
		<div className={classNames.root}>
			{tabs.map((t, i) => (
				<button
					key={`tab-${i}`}
					onClick={() => onChange(i)}
					className={cx(classNames.tab, small && classNames.small)}
				>
					{t}
				</button>
			))}
			<div
				style={{ left: index * (small ? 120 : 210) }}
				className={cx(
					classNames.underline,
					small && classNames['underline-small']
				)}
			/>
		</div>
	);
};
