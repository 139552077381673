import classNames from './Loading.module.css';

export const Loading = () => {
	return (
		<div className={classNames['lds-ellipsis']}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};
