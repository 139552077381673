import { track } from '@amplitude/analytics-browser';
import React from 'react';
import { useRouteError } from 'react-router-dom';

export const ErrorView = (): JSX.Element => {
	const error = useRouteError();

	React.useEffect(() => {
		track('error page');
	}, []);

	return (
		<div
			style={{
				margin: 'auto',
				flexDirection: 'column',
				display: 'flex',
				maxWidth: 200,
			}}
		>
			<h2>Oops</h2>
			<p>Something broke...</p>
			<p>Try refreshing the page to fix the problem</p>
			{/* @ts-ignore */}
			<p>Error: {error?.statusText || error?.message}</p>
		</div>
	);
};
