import { atom } from 'recoil';

export interface ModalState {
	show: boolean;
	data?: Record<string, any>;
}

export interface Modals {
	feedback: ModalState;
}

export const modalsState = atom<Modals>({
	key: 'modals',
	default: {
		feedback: { show: false },
	},
});
