import { firebaseAuth } from 'api/firebase';
import { Loading } from 'components/Loading/Loading';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const VerifyEmail = () => {
	const navigate = useNavigate();

	React.useEffect(() => {
		if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');

			if (!email) {
				email = window.prompt('Please provide your email for confirmation');
			}

			if (email) {
				signInWithEmailLink(firebaseAuth, email, window.location.href).then(
					() => {
						window.localStorage.removeItem('emailForSignIn');
						navigate('/signup');
					}
				);
			}
		}
	}, [navigate]);

	return (
		<div>
			<Loading />
		</div>
	);
};
