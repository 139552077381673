import React from 'react';
import { useRecoilState } from 'recoil';

import { sharedDiscountsState } from 'data/shared-discounts';

import classNames from './SharedDiscounts.module.css';
import { SiteCard } from 'components/SiteCard/SiteCard';
import { PromoRow } from '../PromoRow/PromoRow';

export const SharedDiscounts = ({ filter }: { filter?: string }) => {
	const [
		{
			data: { sharedDiscounts, sitesById },
		},
	] = useRecoilState(sharedDiscountsState);

	const [selectedSite, setSelectedSite] = React.useState<number | null>(null);

	const filteredDiscounts = React.useMemo(() => {
		return Object.entries(sharedDiscounts)
			.map(([siteId, discounts]) => {
				const site = sitesById[parseInt(siteId)];

				if (filter && !site.name.toLowerCase().includes(filter.toLowerCase())) {
					return null;
				}

				return (
					<SiteCard
						onClick={() => setSelectedSite(parseInt(siteId))}
						id={site.id}
						name={site.name}
						key={`site-${siteId}`}
						numDiscounts={discounts.length}
					/>
				);
			})
			.filter(Boolean);
	}, [sharedDiscounts, filter, sitesById]);

	return (
		<div className={classNames.root}>
			<div className={classNames['site-cards-container']}>
				{filteredDiscounts}
				{filteredDiscounts.length === 0 && (
					<div>Your search didn&apos;t match any of our sites</div>
				)}
			</div>
			<div className={classNames['focus-container']}>
				{selectedSite === null && (
					<h3 className={classNames['zero-state']}>
						Select a site on the left to see this months coupons here
					</h3>
				)}
				{selectedSite && (
					<div className={classNames['focus-inner-container']}>
						<>
							<h2 className={classNames['selected-site-header']}>
								{sitesById[selectedSite].name}
							</h2>
							<div className={classNames['focus-discounts-container']}>
								{sharedDiscounts[selectedSite].map((d) => (
									<PromoRow key={`discount-${d.promo_code}`} discount={d} />
								))}
							</div>
						</>
					</div>
				)}
			</div>
		</div>
	);
};
