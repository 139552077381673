import { Discount, GeneralDiscount } from 'api/types';
import { DateTime } from 'luxon';
import React from 'react';

import classNames from './PromoRow.module.css';

export const PromoRow = ({
	discount,
}: {
	discount: Discount | GeneralDiscount;
}): JSX.Element => {
	const [copied, setCopied] = React.useState(false);
	const ct = React.useRef<NodeJS.Timeout | undefined>(undefined);

	const onClick = () => {
		setCopied(true);
		navigator.clipboard.writeText(discount.promo_code);

		ct.current = setTimeout(() => {
			setCopied(false);
		}, 1000);
	};

	React.useEffect(() => {
		return () => clearTimeout(ct.current);
	}, []);

	return (
		<div role="button" onClick={onClick} className={classNames.root}>
			<span>{discount.promo_code}</span>
			{copied && <span>copied</span>}
			{discount?.last_found_at && (
				<span>
					{DateTime.fromISO(discount.last_found_at).toFormat('MM/dd/yyyy')}
				</span>
			)}
		</div>
	);
};
