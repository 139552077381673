import { FcGoogle } from 'react-icons/fc';

import classNames from './GoogleSignIn.module.css';

export const GoogleSignIn = ({
	onClick,
	disabled,
	text,
}: {
	onClick: () => void;
	disabled?: boolean;
	text: string;
}) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={classNames['root']}
		>
			<FcGoogle />
			{text}
		</button>
	);
};
