import React from 'react';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { randomInt } from 'utils';

import classNames from './Button.module.css';

interface ButtonProps {
	children: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	icon?: boolean;
	style?: Record<string, string | number>;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	tooltipText?: string;
	className?: string;
}

const ButtonLoading = () => {
	return <div className={classNames.loading}></div>;
};

export const Button = ({
	children,
	onClick,
	disabled,
	loading,
	icon,
	style,
	tooltipText,
	className,
}: ButtonProps): JSX.Element => {
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	const hasTooltip = !!tooltipText;

	const tooltipId = React.useMemo(() => randomInt().toString(), []);

	return (
		<span
			onMouseEnter={hasTooltip ? () => setShowTooltip(true) : undefined}
			onMouseLeave={
				hasTooltip
					? () => {
							setShowTooltip(false);
					  }
					: undefined
			}
			data-tip
			data-for={`tt-${tooltipId}`}
			className={classNames.wrapper}
		>
			<button
				onClick={onClick}
				className={cx(classNames.root, icon && classNames.icon, className)}
				disabled={disabled || loading}
				style={style}
			>
				{loading ? <ButtonLoading /> : children}
			</button>
			{showTooltip && (
				<ReactTooltip id={`tt-${tooltipId}`}>
					<span>{tooltipText}</span>
				</ReactTooltip>
			)}
		</span>
	);
};
