import { ReactComponent as LogoComponent } from './primary-logo.svg';

import classNames from './Logo.module.css';

export const Logo = ({
	fill,
	width,
	height,
}: {
	fill?: string;
	width: number | string;
	height: number | string;
}) => {
	return (
		<LogoComponent
			style={{ width, height }}
			className={classNames.root}
			fill={fill || '#000000'}
		/>
	);
};
