import React from 'react';
import { useLocation } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import { useRecoilState } from 'recoil';
import { User, userState } from 'data/user';

export const TrackPageView = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const { pathname } = useLocation();

	const [{ initialized }] = useRecoilState<User>(userState);

	React.useEffect(() => {
		if (initialized) {
			track('page view', { pathname });
		}
	}, [initialized, pathname]);

	return <>{children}</>;
};
