import { FiMail, FiTag } from 'react-icons/fi';

import classNames from './SiteCard.module.css';

interface SiteCardProps {
	onClick?: () => void;
	id: number;
	name: string;
	numDiscounts: number;
	numEmails?: number;
}
export const SiteCard = ({
	onClick,
	id,
	name,
	numDiscounts,
	numEmails,
}: SiteCardProps) => {
	return (
		<button key={`site-${id}`} onClick={onClick} className={classNames['root']}>
			<h2 className={classNames['header']}>{name}</h2>
			<span className="flex-center">
				<span className={classNames.counter}>
					<FiTag />
					{numDiscounts || 0}
				</span>
				{numEmails !== undefined && (
					<span className={classNames.counter}>
						<FiMail />
						{numEmails}
					</span>
				)}
			</span>
		</button>
	);
};
