import React from 'react';
import { useRecoilState } from 'recoil';
import { GrClose } from 'react-icons/gr';
import { FaRegCopy } from 'react-icons/fa';

import { User, userState } from 'data/user';

import classNames from './HelpModal.module.css';
import { IconButton } from 'components/IconButton/IconButton';

interface HelpModalProps {
	onClose: () => void;
}
export const HelpModal = ({ onClose }: HelpModalProps) => {
	const [{ username, userSalt }] = useRecoilState<User>(userState);

	const [copied, setCopied] = React.useState<boolean>(false);

	const handleCopyEmail = () => {
		navigator.clipboard.writeText(`${username}-${userSalt}@mycouper.com`);
		setCopied(true);
	};

	React.useEffect(() => {
		if (copied) {
			let timeout = setTimeout(() => setCopied(false), 2000);
			return () => clearTimeout(timeout);
		}
	}, [copied]);

	return (
		<div className={classNames.overlay}>
			<div className={classNames.root}>
				<button onClick={onClose} className={classNames.close}>
					<GrClose />
				</button>
				<span className={classNames.header}>How to use Couper?</span>
				<div style={{ marginTop: 16 }}>
					<div className={classNames.text}>
						This is your couper email address
					</div>

					<div className={classNames['email-container']}>
						<span
							className={classNames.email}
						>{`${username}-${userSalt}@mycouper.com`}</span>
						<span style={{ marginLeft: 8 }}>
							<IconButton tooltipText="copy email" onClick={handleCopyEmail}>
								<FaRegCopy />
							</IconButton>
						</span>
					</div>
					<div className={classNames.text} style={{ marginTop: 8 }}>
						{copied
							? 'Copied email!'
							: 'Click the button to the right of your email to copy it'}
					</div>
				</div>

				<div>
					<div className={classNames.spacer} />
					<div className={classNames.text}>
						Anytime an online store wants your email for a discount, just give
						them your couper email address.
					</div>
					<div className={classNames.spacer} />
					<div className={classNames.text}>
						For stores you shop at often, you can sign up for their mailing
						lists with you couper email address to get coupons regularly.
					</div>
				</div>
			</div>
		</div>
	);
};
