import { api } from 'api/api';
import { GeneralDiscount, Site } from 'api/types';
import { atom } from 'recoil';

export interface SharedDiscountsState {
	initialized: boolean;
	error?: boolean;
	data: {
		sharedDiscounts: Record<Site['id'], GeneralDiscount[]>;
		sitesById: Record<Site['id'], Site>;
	};
}

export const refreshSharedDiscounts =
	async (): Promise<SharedDiscountsState> => {
		try {
			const resp = await api.getSharedDiscounts();
			return {
				data: resp,
				initialized: true,
				error: false,
			};
		} catch {
			return {
				data: { sharedDiscounts: {}, sitesById: {} },
				initialized: true,
				error: true,
			};
		}
	};

export const sharedDiscountsState = atom<SharedDiscountsState>({
	key: 'shared-discounts',
	default: {
		data: { sharedDiscounts: {}, sitesById: {} },
		initialized: false,
		error: false,
	},
});
