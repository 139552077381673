import { useRecoilState } from 'recoil';
import { MdOutlineLogout, MdOutlineFeedback } from 'react-icons/md';

import { User } from 'data/user';
import { modalsState } from 'data/modals';

import classNames from './ProfileMenu.module.css';

interface ProfileMenuProps {
	user: User;
	onLogout: () => void;
	onClose: () => void;
}

export const ProfileMenu = ({ user, onLogout, onClose }: ProfileMenuProps) => {
	const [, setModalsState] = useRecoilState(modalsState);

	return (
		<>
			<div className={classNames.root}>
				<div className={classNames['email-row']}>{user.email}</div>
				<div className={classNames.divider} />
				{/*<button className={classNames['action-row']}>
					<span>Settings</span>
					<MdOutlineSettings />
				</button>
				<div className={classNames.divider} />
				*/}
				<button
					onClick={() => {
						onClose();
						setModalsState({ feedback: { show: true } });
					}}
					className={classNames['action-row']}
				>
					<span>Give Feedback</span>
					<MdOutlineFeedback />
				</button>
				<div className={classNames.divider} />
				<button onClick={onLogout} className={classNames['action-row']}>
					<span>Logout</span>
					<MdOutlineLogout />
				</button>
				<div className={classNames.divider} />
				<div className={classNames['footer-row']}>
					<a href="/terms-of-service" target="_blank">
						Terms of Service
					</a>
					<a href="/privacy-policy" target="_blank">
						Privacy Policy
					</a>
				</div>
			</div>
			<div role="button" onClick={onClose} className={classNames.backdrop} />
		</>
	);
};
