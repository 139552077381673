import React, { useMemo } from 'react';
import { api } from 'api/api';
import { useNavigate } from 'react-router-dom';
import { setUserId, track } from '@amplitude/analytics-browser';
import { useRecoilState } from 'recoil';
import { sendSignInLinkToEmail } from 'firebase/auth';

import { useInputState, useScrollToTop } from 'hooks';
import { isValidEmail, randomInt } from 'utils';
import { firebaseAuth } from 'api/firebase';
import { logger } from 'logger';

import { userState } from 'data/user';
import { EVENTS } from 'amp-events';

import { Button } from 'components/Button/Button';
import { Footer } from 'components/Footer/Footer';
import { GoogleSignIn } from 'components/GoogleSignIn/GoogleSignIn';
import { Loading } from 'components/Loading/Loading';

import * as errorCheckers from './errorCheckers';
import { SignupProgress } from './SignupProgress/SignupProgress';

import classNames from './Signup.module.css';

const getUserSalt = (): string => {
	return `${randomInt(9)}${randomInt(9)}${randomInt(9)}${randomInt(9)}`;
};

export const UnprotectedSignup = (): JSX.Element => {
	const [user, setUser] = useRecoilState(userState);

	const navigate = useNavigate();

	useScrollToTop(true);

	const [username, onChangeUsername, { error: usernameError }] = useInputState({
		getError: errorCheckers.getErrorForUsername,
	});
	const [usernameAlreadyTaken, setUsernameAlreadyTaken] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [acceptedTOSPP, setAcceptedTOSPP] = React.useState(false);
	const [email, onChangeEmail] = useInputState({});

	const userSalt = useMemo(getUserSalt, []);

	const submitUser = () => {
		setIsLoading(true);
		track(EVENTS.signup.clickCreateAccount);
		api
			.createUser({ username, userSalt, acceptedTOSPP })
			.then(({ data }) => {
				// success creating user
				setUserId(data.email);
				setUser({
					hasAuth: true,
					initialized: true,
					username: data.username,
					userSalt: data.userSalt,
					email: data.email,
					isAdmin: data.isAdmin,
				});
				navigate('/home');
			})
			.catch((error) => {
				if (
					error.response.status === 409 &&
					error.response.data === 'username already taken'
				) {
					setUsernameAlreadyTaken(true);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const handleGetStartedWithGoogle = () => {
		track(EVENTS.signup.clickGoogleSignup);
		api.authenticateUserWithGoogle();
	};

	const handleGetStartedWithEmail = () => {
		setIsLoading(true);
		track(EVENTS.signup.clickEmailSignup);
		// https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0&hl=en
		sendSignInLinkToEmail(firebaseAuth, email, {
			url: `${window.origin}/verify-email`,
			handleCodeInApp: true, // this must be true
		})
			.then(() => {
				window.localStorage.setItem('emailForSignIn', email);
				setIsLoading(false);
			})
			.catch((err) => {
				logger.error(err);
			});
	};

	const resetEmail = () => {
		window.localStorage.removeItem('emailForSignIn');
		window.location.reload();
	};

	React.useEffect(() => {
		// if the user has a username they shouldn't be at signup
		if (user.username) {
			navigate('/home');
		}
	}, [user.username, navigate]);

	const awaitingEmailVerification =
		window.localStorage.getItem('emailForSignIn');

	React.useEffect(() => {
		if (awaitingEmailVerification) {
			track(EVENTS.signup.viewStep, { step: 'awaiting email verification' });
		} else if (!user.hasAuth && !awaitingEmailVerification) {
			track(EVENTS.signup.viewStep, { step: 'enter email' });
		} else if (user.hasAuth && user.uninitialized) {
			track(EVENTS.signup.viewStep, { step: 'pick username' });
		}
	}, [user, awaitingEmailVerification]);

	return (
		<>
			<div className={classNames.root}>
				<div className={classNames['form-container']}>
					{/* the user is signed in but hasn't creted an account yet */}
					{user.hasAuth && user.uninitialized && (
						<>
							<span className={classNames.header}>Pick Username</span>
							<input
								onChange={(e) => {
									setUsernameAlreadyTaken(false);
									onChangeUsername(e);
								}}
								className={classNames['username-input']}
								placeholder="enter username"
								onFocus={() => track(EVENTS.signup.focusUsernameField)}
							/>
							<div className={classNames['username-help-container']}>
								{!!username && (
									<span className={classNames['your-email']}>
										your email will be: {username}-{userSalt}@mycouper.com
									</span>
								)}
								{username.length > 0 && usernameError && (
									<div style={{ fontSize: 14 }} className="error-text">
										{usernameError}
									</div>
								)}
								{usernameAlreadyTaken && (
									<div style={{ fontSize: 14 }} className="error-text">
										that username is already taken, please choose another
									</div>
								)}
							</div>

							{/* TOS & PP Agreement */}
							<div
								style={{ fontSize: 14, marginBottom: 8 }}
								className="flex-center"
							>
								<input
									onClick={() => setAcceptedTOSPP(!acceptedTOSPP)}
									value={`${acceptedTOSPP}`}
									type="checkbox"
									id="terms-pp"
								/>
								<label htmlFor="terms-pp">
									<span>
										I agree to the&nbsp;
										<a
											className={classNames['agreements-link']}
											href="/terms-of-service"
											target="_blank"
										>
											Terms of Service
										</a>
										&nbsp;&&nbsp;
										<a
											className={classNames['agreements-link']}
											href="/privacy-policy"
											target="_blank"
										>
											Privacy Policy
										</a>
									</span>
								</label>
							</div>

							<Button
								disabled={
									!username || !!usernameError || isLoading || !acceptedTOSPP
								}
								className={classNames['create-account']}
								onClick={submitUser}
								loading={isLoading}
							>
								Create Account
							</Button>
							<SignupProgress currentStep={1} />
						</>
					)}

					{awaitingEmailVerification && (
						<>
							<div className={classNames.header}>Verify Email</div>
							<div>
								We sent an email to the address you provided. Please follow the
								link in the email to complete your signup.
							</div>
							<button
								onClick={resetEmail}
								className={classNames['different-email']}
							>
								Sign Up with different email
							</button>
							<SignupProgress currentStep={0} />
						</>
					)}

					{/* the user has not signed in yet */}
					{!user.hasAuth && !awaitingEmailVerification && (
						<>
							<div className={classNames.header}>Get Started for Free</div>
							{isLoading ? (
								<Loading />
							) : (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<div className={classNames['email-container']}>
										<input
											className={classNames['email-input']}
											value={email}
											onChange={onChangeEmail}
											placeholder="email"
											onFocus={() => track(EVENTS.signup.focusEmailField)}
										/>
										<Button
											disabled={!isValidEmail(email)}
											onClick={handleGetStartedWithEmail}
										>
											Sign Up
										</Button>
									</div>
									<div style={{ margin: 8 }}>or</div>
									<GoogleSignIn
										text="Continue With Google"
										onClick={handleGetStartedWithGoogle}
									/>
								</div>
							)}
							<SignupProgress currentStep={0} />
						</>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export const Signup = () => <UnprotectedSignup />;
