import React from 'react';

export const useInputState = ({
	defaultValue,
	getError,
}: {
	defaultValue?: string | null;
	getError?: (v: string) => string | null;
}): [
	string,
	(
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => void,
	{ error: string | null; reset: () => void }
] => {
	const [value, setValue] = React.useState<string>(defaultValue ?? '');
	const [error, setError] = React.useState<string | null>(null);

	const onChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		if (getError) {
			setError(getError(e.target.value));
		}
		setValue(e.target.value);
	};

	const reset = () => setValue(defaultValue ?? '');

	return [value, onChange, { error, reset }];
};

export const useDebounce = (cb: (...params: any) => void, delay: number) => {
	const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	const callback = React.useCallback(
		(...params: any) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = null;
			}

			timeoutRef.current = setTimeout(() => cb.apply(null, params), delay);
		},
		[delay, cb]
	);

	return callback;
};

export const useIsFirstRender = () => {
	const [isFirstRender, setIsFirstRender] = React.useState(true);

	React.useEffect(() => setIsFirstRender(false), []);

	return isFirstRender;
};

export const useScrollToTop = (onMount?: boolean) => {
	const resetScroll = React.useCallback(() => {
		window.scrollTo(0, 0);
	}, []);

	const isFirstRender = useIsFirstRender();

	React.useEffect(() => {
		if (isFirstRender && onMount) {
			resetScroll();
		}
	}, [isFirstRender, onMount, resetScroll]);

	return resetScroll;
};
