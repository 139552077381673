import { Outlet } from 'react-router-dom';

import { FeedbackModal } from 'components/FeedbackModal/FeedbackModal';
import { Nav } from 'components/Nav/Nav';
import { TrackPageView } from 'components/TrackPageView/TrackPageView';

export const Root = (): JSX.Element => {
	return (
		<div>
			<Nav background={'light'} />
			<TrackPageView>
				{/* This is to render children routes under the '/' route */}
				<div id="detail">
					<Outlet />
				</div>
			</TrackPageView>

			<FeedbackModal />
		</div>
	);
};
