import React from 'react';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

import { randomInt } from 'utils';

import classNames from './IconButton.module.css';

export const IconButton = ({
	children,
	onClick,
	tooltipText,
	style,
	disabled,
	className,
}: {
	children: React.ReactNode;
	onClick?: () => void;
	tooltipText?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	className?: string;
}) => {
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	const hasTooltip = !!tooltipText;

	const tooltipId = React.useMemo(() => randomInt().toString(), []);

	return (
		<span
			onMouseEnter={hasTooltip ? () => setShowTooltip(true) : undefined}
			onMouseLeave={
				hasTooltip
					? () => {
							setShowTooltip(false);
					  }
					: undefined
			}
			data-tip
			data-for={`tt-${tooltipId}`}
		>
			<button
				disabled={disabled}
				style={style}
				onClick={onClick}
				className={cx(classNames.root, className)}
			>
				{children}
			</button>
			{showTooltip && (
				<ReactTooltip id={`tt-${tooltipId}`}>
					<span>{tooltipText}</span>
				</ReactTooltip>
			)}
		</span>
	);
};
