// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyAdKUaKGgkF4id9fiVu01g7dgrm6e4LVMA',
	authDomain: 'couper-d6680.firebaseapp.com',
	projectId: 'couper-d6680',
	storageBucket: 'couper-d6680.appspot.com',
	messagingSenderId: '138422435178',
	appId: '1:138422435178:web:7e665f9e4d4c2f7d897913',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);

// Google Auth Provider
const gAuthProvider = new GoogleAuthProvider();
gAuthProvider.addScope('profile');
gAuthProvider.addScope('email');
gAuthProvider.addScope('openid');
//gAuthProvider.setCustomParameters({ 'redirect_uri': })

export { firebaseApp, firebaseAuth, firebaseStorage, gAuthProvider };

// === UTILS === //

// TODO: Directly download storage files on the client, need CORS
//	see https://firebase.google.com/docs/storage/web/download-files#cors_configuration
