export enum APIEndpoint {
	LOGIN = '/login',
	COUNT_DISCOUNTS = '/count-discounts',
	WAITLIST = '/waitlist',
	FEEDBACK = '/feedback',

	USER_DISCOUNTS = '/user-discounts',
	SHARED_DISCOUNTS = '/shared-discounts',
	USER = '/user',
	EMAILS = '/emails',
	FORWARD_EMAIL = '/forward-email',
	SUBSCRIPTIONS = '/subscriptions',
	ADMIN_DATA = '/admin-data',
	CREATE_USER = '/create-user',

	TEST = `/test`,
}

export interface TestEmail {
	'headers[to]': string;
	'headers[from]': string;
	'headers[subject]': string;
	html: string;
}

export interface Discount {
	site: number;
	promo_code: string;
	found_at: string;
	last_found_at: string;
	count: number;
	message_id?: string;
}

export interface GeneralDiscount {
	id?: number;
	site: number;
	promo_code: string;
	found_at: string;
	last_found_at?: string;
	count: number;
}

export interface Site {
	id: number;
	name: string;
	discounts: Discount[];
	aliases: string[];
}

export interface Email {
	messageId: string;
	receivedAt: string;
	recipient: string;
	sender: string;
	subjectStart: string;
}

export interface Subscription {
	siteId: number;
	siteName: string;
	discounts: Discount[];
	emails: Email[];
	totalNumEmails: number;
	totalNumDiscounts: number;
}

// copied from backend
export interface MailData {
	sender: string;
	senderDomain: string;
	senderRootDomain: string;
	recipient: string;
	html: string;
	subject: string;
	potentialUsername: string;
	userSalt: string;
	date: Date;
	senderName?: string;
	isServiceAccount?: boolean;
}
