import { atom } from 'recoil';

export interface User {
	hasAuth: boolean;
	email?: string;
	username?: string;
	userSalt?: string;
	initialized: boolean;
	isAdmin?: boolean;
	uninitialized?: boolean;
}

export const userState = atom<User>({
	key: 'user',
	default: {
		hasAuth: false,
		initialized: false,
	},
});
