import { track } from '@amplitude/analytics-browser';
import { DateTime } from 'luxon';
import { useRecoilState } from 'recoil';

import { Subscription } from 'api/types';

import { Button } from 'components/Button/Button';
import { SiteCard } from 'components/SiteCard/SiteCard';
import { Tabs } from 'components/Tabs/Tabs';
import React from 'react';
import { HelpModal } from '../HelpModal/HelpModal';
import { PromoRow } from '../PromoRow/PromoRow';
import classNames from './YourSubscriptions.module.css';
import { EmailRow } from '../EmailRow/EmailRow';
import { ViewEmailModal } from 'components/ViewEmailModal/ViewEmailModal';
import { subscriptionsState } from 'data/subscriptions';

export const YourSubscriptions = ({ filter }: { filter?: string }) => {
	const [selectedSubscription, setSelectedSubscription] =
		React.useState<Subscription | null>(null);
	const [showHelpModal, setShowHelpModal] = React.useState(false);
	const [focusTabIndex, setFocusTabIndex] = React.useState(0);
	const [showEmailId, setShowEmailId] = React.useState<string | null>(null);

	const [{ data: subscriptions }] = useRecoilState(subscriptionsState);

	const sortedSubscriptions = React.useMemo(() => {
		const subs = [...subscriptions];
		subs.sort((a, b) => (a.totalNumDiscounts > b.totalNumDiscounts ? -1 : 1));
		return subs;
	}, [subscriptions]);

	const filteredSubscriptions = React.useMemo(() => {
		return !filter
			? sortedSubscriptions
			: sortedSubscriptions.filter((sub) =>
					sub.siteName.toLowerCase().includes(filter.toLowerCase())
			  );
	}, [sortedSubscriptions, filter]);

	const selectedSortedEmails = React.useMemo(() => {
		if (!selectedSubscription) {
			return [];
		}

		const emails = [...selectedSubscription.emails];
		emails.sort(
			(a, b) =>
				DateTime.fromISO(b.receivedAt).toMillis() -
				DateTime.fromISO(a.receivedAt).toMillis()
		);

		return emails;
	}, [selectedSubscription]);

	const selectedSortedDiscounts = React.useMemo(() => {
		if (!selectedSubscription) {
			return [];
		}

		const discounts = [...selectedSubscription.discounts];
		discounts.sort(
			(a, b) =>
				DateTime.fromISO(b.last_found_at).toMillis() -
				DateTime.fromISO(a.last_found_at).toMillis()
		);

		return discounts;
	}, [selectedSubscription]);

	if (sortedSubscriptions.length === 0) {
		return (
			<div className={classNames['zero-state-root']}>
				<div className={classNames['no-user-sites']}>
					<h3>You haven't received any emails from online stores</h3>
					<Button
						onClick={() => {
							track('opened help modal');
							setShowHelpModal(true);
						}}
						className={classNames['learn-to-use']}
					>
						click here to learn to use couper
					</Button>
				</div>
				{showHelpModal && <HelpModal onClose={() => setShowHelpModal(false)} />}
			</div>
		);
	}

	return (
		<div className={classNames.root}>
			<div className={classNames['site-cards-container']}>
				{filteredSubscriptions.map((sub) => (
					<SiteCard
						onClick={() => setSelectedSubscription(sub)}
						id={sub.siteId}
						name={sub.siteName}
						numDiscounts={sub.discounts.length}
						numEmails={sub.totalNumEmails}
						key={`site-${sub.siteId}`}
					/>
				))}
				{filteredSubscriptions.length === 0 && (
					<div>Your search doesn&apos;t match any of your sites</div>
				)}
			</div>
			<div className={classNames['focus-container']}>
				{selectedSubscription === null && (
					<h3 className={classNames['focus-zero-state']}>
						Select a site on the left to see emails and promo codes they have
						sent you
					</h3>
				)}
				{selectedSubscription && (
					<div className={classNames['focus-inner-container']}>
						<>
							<div className={classNames['focus-header']}>
								<h2 className={classNames['selected-site-header']}>
									{selectedSubscription.siteName}
								</h2>
								<Tabs
									small
									tabs={['Codes', 'Emails']}
									index={focusTabIndex}
									onChange={setFocusTabIndex}
								/>
							</div>
							{focusTabIndex === 0 && (
								<div className={classNames['focus-list']}>
									{selectedSortedDiscounts.map((d) => (
										<PromoRow key={`discount-${d.promo_code}`} discount={d} />
									))}
								</div>
							)}
							{focusTabIndex === 1 && (
								<div className={classNames['focus-list']}>
									{selectedSortedEmails.map((e) => (
										<EmailRow
											onSelect={() => setShowEmailId(e.messageId)}
											key={`discount-${e.messageId}`}
											email={e}
										/>
									))}
								</div>
							)}
						</>
					</div>
				)}
			</div>
			{showEmailId && (
				<ViewEmailModal
					emailId={showEmailId}
					onClose={() => setShowEmailId(null)}
				/>
			)}
		</div>
	);
};
